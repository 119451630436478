import './App.css';
import FaceImage from './YesThisIsMyFace.jpg';
import DrawerAppBar from './Toolbar.js';
import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import X from "@mui/icons-material/X"

function App() {
  const [text, setText] = useState("");
  const [showWelcome, setShowWelcome] = useState(localStorage.getItem('firstVisit') !== 'false');
  const [showGif, setShowGif] = useState(false);
  const fullText = "Welcome to the personal website of Jonah Weinbaum";

  useEffect(() => {
    if (text.length < fullText.length) {
      setTimeout(() => {
        setText(fullText.slice(0, text.length + 1));
      }, 100);
    }
  }, [text]);

  useEffect(() => {
    if (showWelcome) {
      setTimeout(() => {
        setShowGif(true);
        document.body.style.cursor = `url('${process.env.PUBLIC_URL}/Cursors/winaRtist edition normal.cur'), auto`; // change normal cursor
        setTimeout(() => {
          setShowGif(false);
          setShowWelcome(false);
          localStorage.setItem('firstVisit', 'false');
        }, 3500); // adjust the delay for the gif as needed, now set to 70% of the original time
      }, 9000);
    } else {
      setShowGif(false);
      document.body.style.cursor = `url('${process.env.PUBLIC_URL}/Cursors/winaRtist edition normal.cur'), auto`; // change hover cursor
    }
  }, [showWelcome]);

  return (
    <div style={{backgroundColor: '#1C1C1C', height: '150vh', position: 'relative'}}>
      {showGif ? (
        <div style={{position: 'absolute', width: '100%', height: '200vh', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '3em'}}>
          <div style={{position: 'absolute', top: '0', left: '0', width: '100vw', height: '200vh', pointerEvents: 'none', zIndex: 1}}></div>
          <iframe title="FireBall1" src="https://giphy.com/embed/l46CmRhQlMBRQUfDO" width="100%" height="100%" style={{position:'absolute', top: '0', left: '30vw'}} frameBorder="0" ></iframe>
          <iframe title="FireBall2" src="https://giphy.com/embed/l46CmRhQlMBRQUfDO" width="100%" height="100%" style={{position:'absolute', top: '0', left: '0'}} frameBorder="0" ></iframe>
          <iframe title="FireBall3" src="https://giphy.com/embed/l46CmRhQlMBRQUfDO" width="100%" height="100%" style={{position:'absolute', top: '0', left: '-30vw'}} frameBorder="0" ></iframe>
          <iframe title="FireBall4" src="https://giphy.com/embed/l46CmRhQlMBRQUfDO" width="100%" height="100%" style={{position:'absolute', top: '100vh', left: '30vw'}} frameBorder="0" ></iframe>
          <iframe title="FireBall5" src="https://giphy.com/embed/l46CmRhQlMBRQUfDO" width="100%" height="100%" style={{position:'absolute', top: '100vh', left: '0'}} frameBorder="0" ></iframe>
          <iframe title="FireBall6" src="https://giphy.com/embed/l46CmRhQlMBRQUfDO" width="100%" height="100%" style={{position:'absolute', top: '100vh', left: '-30vw'}} frameBorder="0" ></iframe>        
        </div>
      ) : showWelcome ? (
        <div style={{position: 'absolute', width: '100%', height: '100vh', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '3em'}}>
          {text}
          <div style={{paddingTop:'51.200%',position:'absolute', height: '100%', top:'100vh',width:'100%'}}>
            <img src="https://i.gifer.com/1yk4.gif" alt="Scroll Back Up!" style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />
          </div>
        </div>
      ) : (
        <>
      <DrawerAppBar />
         <div style={{position: 'relative', paddingRight: '50vw', paddingTop: '20vh', userSelect: 'none'}}>
           <p style={{fontFamily: 'Arial, sans-serif', fontWeight: 'lighter', color: 'white', fontSize: '2em', paddingLeft: '10vw'}}>
             <span style={{borderBottom: '3px solid #354E5D', paddingBottom: '2px', display: 'inline-block', minWidth: 'calc(3em * 6)'}}>{"Jonah Weinbaum"}</span>
           </p>
           <p style={{fontFamily: 'Arial, sans-serif', fontWeight: 'lighter', color: 'white', fontSize: '1.5em', paddingLeft: '10vw'}}>{"I've built across neuro, energy, and climate. I'm currently focused on AI Safety."}</p>
         </div>
          <img src={FaceImage} alt="Face" style={{position: 'absolute', right: '20px', top: '10vh', width: '40vw', height: 'calc(40vw * 1024 / 768)', maxWidth: '100%', maxHeight: '100%'}}/>
          <div style={{position: 'fixed', bottom: '0', width: '100%', backgroundColor: '#1C1C1C', color: 'white', display: 'flex', justifyContent: 'space-between', padding: '10px 20%'}}> 
            <IconButton color="inherit" href="https://twitter.com/WeinbaumJonah" target="_blank"><X /></IconButton>
            <IconButton color="inherit" href="https://www.linkedin.com/in/weinbaumjonah/" target="_blank"><LinkedInIcon /></IconButton>
            <IconButton color="inherit" href="mailto:weinbaumjonah@gmail.com"><EmailIcon /></IconButton>
          </div>
        </>
      )}
    </div>
  );
}

export default App;

